import { NotImplementedError } from "../../../../../errors";
import { attributionModelFieldsToQueryDimensions, isAttributionFilter, isAttributionQueryMeasure, modelFiltersToLookQuerySort, modelFiltersToQueryFilters } from "../../../../../models/looker/modules/attribution";
import { buildVisConfig } from "../../../../../models/looker/modules/attribution/looks/funnel";
import { Handler } from "../../../../handlers";
import { parseInclusionFilter } from "../../../../handlers/filter";
import axios from "axios";
import { ATTRIBUTION_QUERY } from "../query";
import { ATTRIBUTION_LOOKS_VIS_CONFIG } from "./query";
class Looks extends Handler {
    constructor(sdk) {
        super(sdk, "looker-attribution-looks");
        this.lookUrl = `${this.sdk.urls.burnsBaseUrl}/looker/attribution`;
    }
    async findItems(filters) {
        const lookName = parseInclusionFilter(filters, "lookName", ["string"])
            .values()
            .next().value;
        if (!lookName) {
            throw new Error("Look name filter is required");
        }
        const queryBody = this.buildQuery(filters);
        // Do not cache the Burns response. Regardless of whether the query
        // has already been run, Burns needs to update the underlying Look with
        // the query id. In the future, we could improve this by adding simple
        // query creation caching to the query module (src/lib/modules/looker/
        // query/index.ts).
        return this.sdk.looker.query
            .create(queryBody)
            .then((query) => query.id)
            .then((id) => axios
            .get(`${this.lookUrl}/${lookName}?query_id=${encodeURIComponent(id)}`)
            .then((lookId) => [lookId.data]))
            .catch(() => {
            throw new Error("Failed to fetch look");
        });
    }
    buildQuery(filters) {
        const fields = attributionModelFieldsToQueryDimensions(filters.fields || []);
        const measures = fields.filter(isAttributionQueryMeasure);
        const queryFilters = modelFiltersToQueryFilters(filters.where?.filter((f) => isAttributionFilter(f.field)));
        const sorts = [modelFiltersToLookQuerySort(filters.where)];
        const visConfig = {
            ...ATTRIBUTION_LOOKS_VIS_CONFIG,
            ...buildVisConfig(measures)
        };
        return {
            ...ATTRIBUTION_QUERY,
            fields,
            filters: queryFilters,
            sorts,
            // TO-DO: decide whether to make limit dynamic / configurable
            limit: "15",
            vis_config: visConfig
        };
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Looks;
